import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
// import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import * as xml2js from "xml2js";
// import * as JsonToXML from "js2xmlparser";
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('animationOption1', [
      state('start', style({
        backgroundColor: 'yellow',
        width: '150px',
        height: '150px'
      })),
      state('end', style({
        backgroundColor: 'green',
        width: '300px',
        height: '300px'
      })),
      transition('start => end', animate(1500)),
      transition('end => start', animate('800ms 0.5s ease-out'))
    ]),
    trigger('animationOption2', [
      state('close', style({
        opacity: 0,
        backgroundColor: 'yellow'
      })),
      state('open', style({
        opacity: 1,
        backgroundColor: 'green'
      })),
      transition('close <=> open', animate(3000)),
    ])
  ]
})
export class AppComponent {
  title = 'rates';
  
  totalAngularPackages: any;
  errorMessage: any;
  apiRoot: string;
  xml2js;
  string: string;
  xml: string;
  json: string;
  loanDetails: string;
  rate: string;
  price: string;
  originationFee: string;
  apr: string;
  prepayType: string;
  indexValue: string;
  margin: string;
  cap1: string;
  cap2: string;
  cap3: string;
  piti: string;
  downPayment: string;
  loanAmount: string;
  upfrontFee: string;
  monthlyPremium: string;
  results: any;
  isMenuOpen = false;

  clickedDivState = 'start';
  loanAmounts: any;
  propertyState: any;
  productList: any;
  propertyStates: any;
  productLists: any;
  isLoaded: boolean;
  loanProducts: any;
  lp1: any;
  lp2: any;
  lp3: any;

  
  constructor(private http: HttpClient,  private activatedRoute: ActivatedRoute){
    this.activatedRoute.queryParams.subscribe(params => {
     
      this.loanAmounts = params['loan_amount']
      this.propertyStates = params['propertyState']
      this.productLists = params['productList']
      this.loanProducts = params['loanProducts']
      this.lp1 = params['loanProduct1']
      this.lp2 = params['loanProduct2']
      this.lp3 = params['loanProduct3']
      
      console.log(this.loanAmounts, this.propertyStates, this.productLists); 
      // Print the parameter to the console. 
      if(this.loanAmounts == undefined) {
        console.log('no params yet')
        this.isLoaded = false
      }
      else {
       this.isLoaded = true
        this.callAPI()
      }
  });
  }
  ngOnInit() {
   
 
}
// https://thirdparty.mortech-inc.com/mpg/servlet/mpgThirdPartyServlet?
// request_id=1&customerId={{customerId}}&thirdPartyName={{thirdPartyName}}
// &licenseKey={{licenseKey}}&emailAddress={{emailAddress}}&loan_amount=
// 	SURSHUW\6WDWH &$	ORDQ3URGXFW \HDU¿[HG	ORDQ3URGXFW \HDU
// ¿[HG	ORDQ3URGXFW \HDU$50\UV
  async callAPI(){
    await this.apiRoot
   this.apiRoot = 'https://thirdparty.mortech-inc.com/mpg/servlet/mpgThirdPartyServlet?request_id=1&customerId=travis&thirdPartyName=TravisEckhardt&licenseKey=MortechTravis&emailAddress=teckhardt@mortech-inc.com&'+'loan_amount='+ this.loanAmounts + '&' + 'propertyState=' + this.propertyStates + '&' + 'productList='+ this.productLists

  // this.apiRoot = 'https://thirdparty.mortech-inc.com/mpg/servlet/mpgThirdPartyServlet?request_id=1&customerId=travis&thirdPartyName=TravisEckhardt&licenseKey=MortechTravis&emailAddress=teckhardt@mortech-inc.com&'+'loan_amount='+ this.loanAmounts + '&' + 'propertyState=' + this.propertyStates + '&' + 'loanProduct=loanProduct5'

    console.log(this.apiRoot)
       this.http.get(this.apiRoot, {responseType: 'text'}).subscribe({
         next: data => {
             this.totalAngularPackages = data;
             const p: xml2js.Parser = new xml2js.Parser();
             p.parseString(data, (err, result) => {
           this.results = result
             const json =JSON.stringify(result.mortech.results[0].quote[0].$.vendor_product_name, null, 6); 
             this.loanDetails =JSON.stringify(result.mortech.results[0].quote[0].quote_detail[0].$, null, 6); 
             this.rate = JSON.stringify(result.mortech.results[0].quote[0].quote_detail[0].$.rate, null, 6);
             this.apr = JSON.stringify(result.mortech.results[0].quote[0].quote_detail[0].$.apr, null, 6); 
             this.loanAmount = JSON.stringify(result.mortech.results[0].quote[0].quote_detail[0].$.loanAmount, null, 6);
         
   
           this.json = json
           console.log(result)
             }),
           
         this.xml = data
        
         
         },
         error: error => {
             this.errorMessage = error.message;
             console.error('There was an error!', error);
         }
     }) 
  }
changeDivState() {
  this.clickedDivState = 'end';
  setTimeout(() => {
    this.clickedDivState = 'start';
  }, 3000);
}

toggleMenu(): void {
  this.isMenuOpen = !this.isMenuOpen;
}

loadRate(){
  this.callAPI()
}
  
  
}

