


<section class=" p-5 grey center">
  <h4>Choose Your Adventure</h4>
  <div class="center" style="width: 90%; display: inline-block;">
    <div class="row justify-content-center">
  
      <div class="col-3 mb-3 ">
        <div class="card drop-shadow border-radius  pt-4 center" style="width: 100%;"> 
          <h5 class="card-title m-3">{{json | quote}}</h5>
          <div class="row m-3">
              <div class="col-sm-6 center">
              
                <h6>Rate</h6>
               
                  <h4 class="center bold" style="font-weight: 900;">{{rate | quote}}%</h4>
                 
              </div>
              <div class="col-sm-6 center">
                
                <h6 class="center">APR</h6>
              
                  <h4 class="center bold" >{{apr | quote}}%</h4>
                
              </div>
          </div>
         
          <div>
           
            <hr class="center"style="border-bottom: 1pt solid rgb(235, 233, 233);width: 70%; text-align: center; display: inline-block;margin-top:0">
         
            <p class="small">Last updated: {{results?.mortech?.results[0].quote[0].$.lastUpdate  | amLocale:'en' | amDateFormat:'MMMM Do YYYY, h:mm:ss a'}}</p>
         
          </div>
        </div>
      </div>
   <div class="col-3 mb-3 "> 
        <div class="card drop-shadow border-radius  pt-4 center" style="width: 100%;"> 
          <h5 class="card-title m-3">{{results?.mortech?.results[1].quote[0].$.vendor_product_name}}</h5>
          <div class="row m-3">
              <div class="col-sm-6 center">
              
                <h6>Rate</h6>
               
                  <h4 class="center bold" style="font-weight: 900;">{{results?.mortech?.results[1].quote[0].quote_detail[0].$.rate}}%</h4>
                 
              </div>
              <div class="col-sm-6 center">
                
                <h6 class="center">APR</h6>
              
                  <h4 class="center bold" >{{results?.mortech?.results[1].quote[0].quote_detail[0].$.apr}}%</h4>
                
              </div>
          </div>
        
          <div >
           
            <hr class="center"style="border-bottom: 1pt solid rgb(235, 233, 233);width: 70%; text-align: center; display: inline-block;margin-top:0">
         
            <p class="small">Last updated: {{results?.mortech?.results[3].quote[0].$.lastUpdate  | amLocale:'en' | amDateFormat:'MMMM Do YYYY, h:mm:ss a'}}</p>
         
          </div>
        </div>
      </div>
      <div class="col-3 mb-3 ">
        <div class="card drop-shadow border-radius  pt-4 center" style="width: 100%;"> 
          <h5 class="card-title m-3">{{results?.mortech?.results[2].quote[0].$.vendor_product_name}}</h5>
          <div class="row m-3">
            <div class="col-sm-6 center">
            
              <h6>Rate</h6>
             
                <h4 class="center bold" style="font-weight: 900;">{{results?.mortech?.results[2].quote[0].quote_detail[0].$.rate}}%</h4>
               
            </div>
            <div class="col-sm-6 center">
              
              <h6 class="center">APR</h6>
            
                <h4 class="center bold" >{{results?.mortech?.results[2].quote[0].quote_detail[0].$.apr}}%</h4>
              
            </div>
        </div>
         
          <div>
           
            <hr class="center"style="border-bottom: 1pt solid rgb(235, 233, 233);width: 70%; text-align: center; display: inline-block;margin-top:0">
         
            <p class="small">Last updated: {{results?.mortech?.results[3].quote[0].$.lastUpdate  | amLocale:'en' | amDateFormat:'MMMM Do YYYY, h:mm:ss a'}}</p>
         
          </div>
        </div>
      </div>
      <div class="col-3 mb-3 ">
        <div class="card drop-shadow border-radius  pt-4 center" style="width: 100%;"> 
          <h5 class="card-title m-3">{{results?.mortech?.results[3].quote[0].$.vendor_product_name}}</h5>
          <div class="row m-3">
            <div class="col-sm-6 center">
            
              <h6>Rate</h6>
             
                <h4 class="center bold" style="font-weight: 900;">{{results?.mortech?.results[3].quote[0].quote_detail[0].$.rate}}%</h4>
               
            </div>
            <div class="col-sm-6 center">
              
              <h6 class="center">APR</h6>
            
                <h4 class="center bold" >{{results?.mortech?.results[3].quote[0].quote_detail[0].$.apr}}%</h4>
              
            </div>
        </div>
         
          <div>
           
            <hr class="center"style="border-bottom: 1pt solid rgb(235, 233, 233);width: 70%; text-align: center; display: inline-block;margin-top:0">
         
            <p class="small">Last updated: {{results?.mortech?.results[3].quote[0].$.lastUpdate  | amLocale:'en' | amDateFormat:'MMMM Do YYYY, h:mm:ss a'}}</p>
           
          </div>
        </div>
      </div> 
   
    </div>
  </div>
  
 
</section>
<section class=" p-5 center">
  <div class="center" style="width: 100%; display: inline-block;">
    <div class="row justify-content-center">
  <div class="col-3 mb-3 ">
    <h4>{{results?.mortech?.results[0].quote[0].$.vendor_product_name}}</h4>
  <pre style="text-align: left;">{{results?.mortech?.results[0] | json}} </pre>
</div>
<div class="col-3 mb-3 ">
  <h4>{{results?.mortech?.results[1].quote[0].$.vendor_product_name}}</h4>
<pre style="text-align: left;">{{results?.mortech?.results[1] | json}} </pre>
</div>
<div class="col-3 mb-3 ">
  <h4>{{results?.mortech?.results[2].quote[0].$.vendor_product_name}}</h4>
<pre style="text-align: left;">{{results?.mortech?.results[2] | json}} </pre>
</div>
<div class="col-3 mb-3 ">
  <h4>{{results?.mortech?.results[3].quote[0].$.vendor_product_name}}</h4>
<pre style="text-align: left;">{{results?.mortech?.results[3] | json}} </pre>
</div>
</div>
</div>
</section>


